import { useForm } from "react-hook-form";
import styled from "styled-components";

import { StandaloneSearchBox } from "@react-google-maps/api";
import TableLoadingSpinner from "../../ui/table/TableLoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import {
  EVENT_CALENDAR_URL,
  DESCRIPTION_FIELD,
  LOGGING_TYPE
} from "@/constants/calendar";
import useCalendarStore from "@/store/calendarStore";
import { FaPlus } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { isMobile } from "react-device-detect";
import { useAddEvents, useFiles, useSearchAddress } from "./eventFormHooks";
import { makeEventForm } from "./eventFormUtils";
import Speakers from "../Speakers";
import { handleGoogleAnalytics } from "@/utils";

const EventForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const { addEvent, loading } = useAddEvents();
  const { timezone, setTimezone } = useCalendarStore();

  const {
    flyerRef,
    documentRef,
    flyer,
    previewUrl,
    document,
    uploadFlyer,
    handleFlyerChange,
    uploadDocument,
    handleDocumentChange,
    handleDeleteFlyer,
    handleUploadFile,
    handleDocumentDelete
  } = useFiles();

  const {
    onLoad,
    onPlacesChanged,
    inputRef: locationInputRef,
    address
  } = useSearchAddress();

  const [speakers, setSpeakers] = useState([]);

  const duration = watch("duration");
  const isSpecific = !duration;

  const startDate = watch("start");

  useEffect(() => {
    setValue("duration", true);
  }, []);

  useEffect(() => {
    if (startDate) {
      if (isSpecific) {
        const date = new Date(startDate);
        date.setTime(date.getTime() + 10 * 60 * 60 * 1000);

        setValue("end", date.toISOString().slice(0, 16));
      } else {
        setValue("end", startDate);
      }
    }
  }, [startDate]);

  const onSubmit = async data => {
    const dataWithLocation = {
      ...data,
      "Location(EN)": address.en,
      "Location(KR)": address.ko
    };
    const event = makeEventForm(dataWithLocation, timezone, speakers);
    const response = await addEvent(event);
    const key = response.id;

    await handleUploadFile(key);

    alert(`Submission successful. Will be posted in 6h after content check.`);

    const author = watch("Posting Author");
    const email = watch("Posting Author Email");
    handleGoogleAnalytics({
      eventType: LOGGING_TYPE.eventAdd,
      eventLabel: `${author}_${email}`
    });

    navigate(EVENT_CALENDAR_URL);
  };

  const handleChangeTimeZone = timezone => {
    setTimezone(timezone.value);
  };

  const addSpeaker = e => {
    let havetoAdd = false;
    if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
      e.preventDefault();
      havetoAdd = true;
    } else if (e._reactName === "onClick") {
      e.stopPropagation();
      havetoAdd = true;
    }

    const speaker = watch(DESCRIPTION_FIELD[6]);
    if (!havetoAdd || !speaker) return;

    setValue(DESCRIPTION_FIELD[6], "");
    if (!speakers.find(item => item === speaker))
      setSpeakers(prev => {
        return [...prev, speaker];
      });
  };

  return (
    <Section $isloading={loading}>
      {loading && (
        <SpinnerSection>
          <TableLoadingSpinner width={"3rem"} height={"3rem"} />
        </SpinnerSection>
      )}
      <Wrapper $ismobile={isMobile}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isMobile ? (
            <></>
          ) : (
          <IoCloseSharp cursor={'pointer'} 
          style={{position : 'absolute', top : '1rem', right: '0rem'}}
          size={'2rem'} 
          onClick={() => navigate(EVENT_CALENDAR_URL)} />
          )}
          <InputSection>
            <InputItem>
              <Label>
                Title
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Title"
                {...register("summary", { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                Schedule
                <Required>*</Required>
              </Label>
              <InputField>
                <AlldayCheckBox>
                  <input type="checkbox" {...register("duration")} />
                  All Day
                </AlldayCheckBox>
                {isSpecific && (
                  <TimezoneSelect
                    value={timezone}
                    onChange={handleChangeTimeZone}
                    styles={{
                      control: base => ({
                        ...base,
                        width: isMobile ? "20rem" : "12rem",
                        height: "2.5rem",
                        textAlign: "center"
                      }),
                      indicatorSeparator: base => ({
                        ...base,
                        display: "none"
                      })
                    }}
                  />
                )}
              </InputField>
            </InputItem>
            <InputItem>
              <InputField>
                <DateInput
                  type={isSpecific ? "datetime-local" : "date"}
                  {...register("start", { required: true })}
                />
                <span>~</span>
                <DateInput
                  type={isSpecific ? "datetime-local" : "date"}
                  {...register("end", { required: true })}
                />
              </InputField>
            </InputItem>
            <InputItem>
              <Label>
                Location
                <Required>*</Required>
              </Label>
              <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <Input
                  ref={locationInputRef}
                  type="text"
                  placeholder="Enter Location"
                  {...register("location", { required: true })}
                />
              </StandaloneSearchBox>
            </InputItem>
            <InputItem>
              <Label>
                {DESCRIPTION_FIELD[0]}
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                {...register(DESCRIPTION_FIELD[0], { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>{DESCRIPTION_FIELD[1]}</Label>
              <Input
                type="text"
                placeholder="Enter Contact"
                {...register(DESCRIPTION_FIELD[1])}
              />
            </InputItem>
            <InputItem>
              <Label>
                {DESCRIPTION_FIELD[2]}
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Link"
                {...register(DESCRIPTION_FIELD[2], { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                {DESCRIPTION_FIELD[3]}
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                {...register(DESCRIPTION_FIELD[3], { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                {DESCRIPTION_FIELD[4]}
                <Required>*</Required>
              </Label>
              <Input
                type="email"
                placeholder="Enter Email"
                {...register(DESCRIPTION_FIELD[4], { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>{DESCRIPTION_FIELD[5]}</Label>
              <Input
                type="tel"
                placeholder="Enter Phone Number"
                {...register(DESCRIPTION_FIELD[5])}
              />
            </InputItem>
            <InputItem>
              <Label>
                {DESCRIPTION_FIELD[6]}
                <Speakers speakers={speakers} setSpeakers={setSpeakers} />
              </Label>
              <Input
                type="text"
                placeholder="Enter Speakers"
                onKeyDown={e => addSpeaker(e)}
                {...register(DESCRIPTION_FIELD[6])}
              />
              <PlusBtn onClick={addSpeaker}>
                <FaPlus />
              </PlusBtn>
            </InputItem>
            <InputItem>
              <Label>Files</Label>
              <AddDocument onClick={uploadDocument}>
                {document ? (
                  <>
                    {document.name}
                    <DeleteIcon
                      src="/svg/delete-trash.svg"
                      alt="delete"
                      onClick={handleDocumentDelete}
                    />
                  </>
                ) : (
                  <>
                    <Icon src="/svg/file.svg" />
                    <span>Add Document</span>
                  </>
                )}
              </AddDocument>
              <FlyerSection onClick={uploadFlyer}>
                {flyer ? (
                  <>
                    <DeleteBtn onClick={handleDeleteFlyer}>
                      <Icon src="/svg/delete-trash.svg" alt="delete" />
                      Delete
                    </DeleteBtn>
                    <Flyer src={previewUrl} alt="Preview" />
                  </>
                ) : (
                  <AddFlyer>
                    <Icon src="/svg/file.svg" />
                    Add Flyer
                  </AddFlyer>
                )}
                <FileInput
                  type="file"
                  onChange={handleFlyerChange}
                  ref={flyerRef}
                />
              </FlyerSection>
              <FileInput
                type="file"
                ref={documentRef}
                onChange={handleDocumentChange}
              />
            </InputItem>
            <InputItem>
              <Label>{DESCRIPTION_FIELD[9]}</Label>
              <TextArea rows={3} {...register(DESCRIPTION_FIELD[9])} />
            </InputItem>
            <SubmitButton type="submit">Submit</SubmitButton>
          </InputSection>
        </Form>
      </Wrapper>
    </Section>
  );
};

export default EventForm;

const SpinnerSection = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const Section = styled.section<{ $isloading?: boolean }>`
  width: 100%;
  position: relative;
  height: 100dvh;
  display: flex;
  align-items: start;
  overflow-y: ${({ $isloading }) => ($isloading ? "hidden" : "auto")};
  padding-bottom: 2rem;
  z-index: 2;
`;

export const Wrapper = styled.div<{ $ismobile: boolean }>`
  width: ${({ $ismobile }) => ($ismobile ? "100%" : "80%")};
  padding-top: ${({ $ismobile }) => ($ismobile ? "2rem" : "4rem")};
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FlyerSection = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  background-color: #e9e9e9;
  border-radius: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const AddFlyer = styled.section`
  position: absolute;
  padding: 0.2rem 0.5rem;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 700;
  transform: translate(-50%, -50%);
  filter: invert(0.5);
`;

export const InputSection = styled.section`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DeleteBtn = styled.section`
  position: absolute;
  padding: 0.2rem 0.5rem;
  top: 100%;
  right: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;
`;

const Icon = styled.img`
  width: 1rem;
  height: 1rem;
  filter: invert(1);
`;

export const InputItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
`;

const Label = styled.span`
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const RadioBtn = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const InputField = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
`;

const SubmitButton = styled.button`
  box-sizing: border-box;
  height: 2.5rem;
  min-height: 38px;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #001326;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #fccb16;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  min-height: 38px;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 2rem;
  font-size: 1rem;
  font-family: inherit;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #e9e9e9 inset !important;
    background-clip: text;
  }

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const DateInput = styled(Input)`
  text-align: center;
  padding-right: 1rem;
`;

const FileInput = styled(Input)`
  display: none;
`;

export const Flyer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const AddDocument = styled.section`
  font-family: inherit;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  min-height: 38px;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 2rem;
  > span {
    filter: invert(0.5);
  }

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const DeleteIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Required = styled.span`
  color: red;
  padding-left: 2px;
  font-weight: 700;
`;

const TextArea = styled.textarea`
  font-family: inherit;
  min-height: 5rem;
  box-sizing: border-box;
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 1rem;
  font-size: 1rem;

  resize: none;

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const PlusBtn = styled.div`
  position: absolute;
  bottom: 0.25rem;
  right: 1rem;

  width: 3rem;
  height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2rem;
  background-color: #ffffff;
  filter: invert(1);
  cursor: pointer;

  //TimezoneSelect가 38px보다 작아지지 않기 때문에, 이에 따른 위치 조정ㄴ
  @media all and (max-width: 1499px) {
    bottom: 9.5px;
  }
`;

const AlldayCheckBox = styled.div`
  height: 2.5rem;
  min-height: 38px;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  input {
    font-size: 1rem;
    margin-left: 0;
  }
`;
