import styled from "styled-components";

import { EVENT_CALENDAR_URL } from "@/constants/calendar";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SearchModule from "./searchModule/SearchModule";
import SearchResults from "./searchResults/SearchResults";
import { IoCloseSharp } from "react-icons/io5";

const Search = () => {
  const navigate = useNavigate();

  const CloseBtnClick = () => {
    navigate(EVENT_CALENDAR_URL);
  };

  return (
    <Layout $ismobile={isMobile}>
      {isMobile ? (
        <></>
      ) : (
        <IoCloseSharp cursor={'pointer'} size={'2rem'} onClick={CloseBtnClick} />
      )}
      <SearchModule />
      <SearchResults />
    </Layout>
  );
};

export default Search;

export const Layout = styled.main<{ $ismobile: boolean }>`
  position: relative;
  width: ${({ $ismobile }) => ($ismobile ? "90%" : "80%")};
  height: 100%;
  margin: 0 auto;
  padding-top: 4rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;
